<template>
  <div id="index">
    <div id="head">
      <div class="title">
        <Icon type="ios-bug-outline" />
        吾八戈软件工作室
      </div>
      <div class="title">
        <Icon type="ios-search-outline" />
      </div>
    </div>
    <div id="content">
      <div class="title" style="color: black">最新动态</div>
      <div class="row">
        <div class="item">
          <div class="miniTitle">云迹录小程序开发</div>
          <Steps :current="7" direction="vertical">
            <Step title="策划"></Step>
            <Step title="小程序UI"></Step>
            <Step title="服务端开发"></Step>
            <Step title="内部测试"></Step>
            <Step title="提交审核"></Step>
            <Step title="发布预览版"></Step>
            <Step title="限定公开测试"></Step>
            <Step title="正式发布"></Step>
          </Steps>
        </div>
        <div class="item">
          <div class="miniTitle">“动感视窗”桌面壁纸软件开发</div>
          <Steps :current="7" direction="vertical">
            <Step title="策划"></Step>
            <Step title="UI设计"></Step>
            <Step title="架构设计"></Step>
            <Step title="客户端开发"></Step>
            <Step title="限定邀请测试"></Step>
            <Step title="官网设计、开发"></Step>
            <Step title="优化"></Step>
            <Step title="正式发布"></Step>
          </Steps>
        </div>
        <div class="item">
          <div class="miniTitle">“帮你选螺蛳粉”小程序开发</div>
          <Steps :current="7" direction="vertical">
            <Step title="策划"></Step>
            <Step title="登记、备案"></Step>
            <Step title="小程序UI"></Step>
            <Step title="服务端开发"></Step>
            <Step title="优化"></Step>
            <Step title="提交审核"></Step>
            <Step title="内部测试"></Step>
            <Step title="正式发布"></Step>
          </Steps>
        </div>
      </div>
    </div>
    <div id="foot">
      <p class="line">
        推荐链接： <a href="https://shop498897168.taobao.com/">淘宝店铺</a>、
        <a href="http://iviewui.com/">IViewUI</a>、
        <a href="https://dgsc.nobugltd.com/">动感视窗</a>
      </p>
      <p class="line">
        Copyright&nbsp;©&nbsp;2023&nbsp; 柳州市柳江区吾八戈应用软件开发工作室&nbsp;
        版权所有&nbsp;
        <a href="https://beian.miit.gov.cn/">{{ beian }}</a>
        &nbsp;
        <a href="https://www.12377.cn/">违法和不良信息举报中心</a>
        &nbsp;
        <a href="mailto:wubageltd@qq.com?subject=官网联系">联系邮箱：wubageltd@qq.com</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      beian: "桂ICP备2023003809号-1",
    };
  },
  mounted() {
    console.log("#####");
    if (location.host.indexOf("sosharp.cn") > -1) {
      this.beian = `桂ICP备2023003809号-1`;
    } else if (location.host.indexOf("nobugltd.com") > -1) {
      this.beian = `桂ICP备2023003809号-2`;
    } else if (location.host.indexOf("nobugltd.cn") > -1) {
      this.beian = `桂ICP备2023003809号-3`;
    }
  },
};
</script>
<style src="@/assets/css/base.css"></style>
<style>
html {
  /*background: #eef1f4;*/
  background: white;
}

#index {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

#head {
  width: 100%;
  background: #54bcbd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  align-content: center;
  padding-left: 10rem;
  padding-right: 10rem;
  box-sizing: border-box;
  flex-shrink: 0;
}

.title {
  font-size: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}

#content {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 10rem 30px 10rem;
}

.item+.item {
  margin-left: 50px;
}

#foot {
  height: 70px;
  background: #54bcbd;
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 10rem;
}

.line {
  margin-top: 10px;
}

.line a {
  color: white;
}

.line a:hover {
  color: rgba(243, 188, 6, 0.863);
}

.miniTitle {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  width: 8px;
  height: 15px;
  border-radius: 5px;
  background: #e2e1e2;
}

*::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}
</style>
